.accordions {
    border-bottom: 1px solid $gray-300;
}
.accordion-header {
    border-top: 1px solid $gray-300;
    color: $black;
    cursor: pointer;
    margin: 0;
    padding: 0;
    user-select: none;
    display: flex;
    align-items: center;

    &:hover {
        background: $gray-100;
    }

    .accordion-header-title {
        margin: 0;
        flex-grow: 1;
        padding: 0.75rem 0.25rem;
        display: flex;
    }

    .accordion-header-title-text {
    }

    .accordion-icon {
        padding-right: 0.25rem;

        i {
            display: inline-block;
            transform: rotate(0deg);
            transition: transform ease 0.35s;
        }
    }

    .collapsed {
        color: $gray-700;

        &:hover {
            color: $black;
        }

        .accordion-icon i {
            transform: rotate(-90deg);
        }
    }

    .accordion-secondary-action {
        border-left: 1px solid $gray-300;
        color: $gray-700;
        padding: 0.75rem;
        white-space: nowrap;

        &:hover {
            color: $white;
            background: $brand;
        }
    }
}

.accordion-content {
    padding-bottom: 2rem;
}
