.btn-bar{
	margin-left: 10px;
    @include media-breakpoint-up(md) {
        display: flex;
        justify-content: space-between;
    }
}

.btn-bar > div {
    margin-bottom: 1rem;
    @include media-breakpoint-up(md) {
        margin-bottom: 0;
    }
}
