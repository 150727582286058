.table {
	thead{
		th{
			//font-weight: 200;
			//border-bottom-width: 0;
		}
	}

	tbody + tbody {
		//Offset bootstrap hardcoded style
		border-top-width: 0;
	}

	.drawer{
	    box-shadow: inset 0 15px 15px -15px $black;
	}
}

.table > :not(:first-child) {
    border-top: none;
}

.table.align-center {
	th,
	td{
		vertical-align: middle;
	}
}
