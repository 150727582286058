.typeahead {
  position: relative;
  display: inline-block;
}

.typeahead-results {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 10;
  box-shadow: 0px 3px 3px rgb(0 0 0 / 30%);
}

.typeahead-results-item {
  cursor: pointer;
}

.typeahead-results-item:hover {
  background: lighten($brand, 50%);
}
