.carousel-fade{
    .carousel-item.active,
    .carousel-item-next,
    .carousel-item-prev
    {
        /*SET IN BS AS FLEXBOX. SO PERSPECTIVE SCALE DID NOT WORK*/
        display:block;
    }

    .carousel-inner>.carousel-item>a>img,
    .carousel-inner>.carousel-item>img,
    .img-responsive
    {
        display: block;
        max-width: 100%;
        height: auto;
    }

    .carousel-inner{

        .carousel-item {
            transition-property: opacity;
        }

        .carousel-item,
        .active.carousel-item-left,
        .active.carousel-item-right
        {
            opacity:0;
        }

        .active,
        .carousel-item-next.carousel-item-left,
        .carousel-item-previous.carousel-item-right
        {
            opacity: 1;
        }
    }

    .carousel-item.active,
    .active.carousel-item-left,
    .active.carousel-item-prev,
    .carousel-item-next.carousel-item-left,
    .carousel-item-prev.carousel-item-right
    {
        left: 0;
        transform: translate3d(0, 0, 0);
    }
}
