body {
    @include media-breakpoint-up(md) {
        padding-bottom: $footer-desktop-height;//Spacing for the footer
        position: relative;
    }
}

footer {
    background-color: $gray-900;
    @include media-breakpoint-up(md) {
        bottom: 0;
        display: flex;
        flex-direction: column;
        height: $footer-desktop-height;
        justify-content: flex-end;
        left: 0;
        position: absolute;
        right: 0;
        text-align: left;
    }
}

.footer-navbar {
    background-color: $gray-900;
    padding: .25rem $spacer*2;
    text-align: center;

    *,
    a{
        color: $gray-200;
    }

    a:hover{
        color: $brand;
        text-decoration: none;
    }

    .footer-logo{
        font-weight: bold;
        padding: 0.5rem 1rem;
        text-transform: uppercase;
        img{
            margin-right: .25rem;
        }
    }

    nav{
        font-size: 0.8rem;
    }
}

.footer-copyright {
    background-color: $gray-800;
    border-top: 1px solid $gray-600;
    color: $gray-600;
    font-size: .8rem;
    padding: .5rem;
    text-align: center;
}
