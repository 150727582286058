.imageEdit{
	position: relative;
	overflow: hidden;
}

.imageEdit-overlay{
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.8);
	opacity: 0;
}

.imageEdit:hover .imageEdit-overlay,
.imageEdit-overlay.show{
	opacity: 1;
} 

.imageEdit-input{
	opacity: 0;
	position: absolute;
	top: 0;
	left: 0;
	font-size: 80vw;
	cursor: pointer;
}

.imageEdit-overlay-message{
	color: $white;
	text-align: center;
	top: 45%;
	left: 0;
	right: 0;
	position: absolute;
}