.roundsNav{
	background-color: $gray-100;
	
	.nav-item{
		color: $gray-700;
		font-size: .7rem;
		padding: .5rem 0;
		text-decoration: underline;
	}
	
	.nav-item.active{
		background-color: $gray-600;
		color: $white;
	}
}