.list-events{

	color: $gray-600;

	.event-title{
		color: $gray-700;
		margin-bottom: .2rem;
	}

	.date{
		& > div{
			font-weight: 200;
			margin: 0;
			text-align: center;

			@include media-breakpoint-down(sm) {
				display: inline-block;
				font-size: $font-size-base;
				text-align: left;
			}
		}
	}

	.event-details{
		font-size: .9rem;
	}


	.list-group-item{
		border-left: 0;
		border-right: 0;
	}
}
