.sidePanel-header{
	border-bottom: 1px solid $gray-400;
	padding: .5rem;
}

.sidePanel + .sidePanel .sidePanel-header{
	border-top-width: 0;
}

.sidePanel-content{
	padding: .5rem 0;
}