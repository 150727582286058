$transition-speed: 0.4s;

// Containers
.brackets-overflow {
  overflow: hidden;
  margin-bottom: 1rem;
}

.brackets-display {
  transition: transform ease $transition-speed;
}

.bracketWrapper {
  page-break-after: always;

  .print & {
    @include printPage;
  }

  @include print {
    width: auto;
  }
}

.bracket {
  display: flex;
  font-size: small;
  padding: $spacer 0;
  page-break-after: always;
  position: relative;

  @include print {
    margin: 0 auto;
    flex-wrap: wrap;
  }
}
// Rounds
.round {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0 $bracket-round-padding;
  min-width: $bracket-round-width;
  max-height: 10000vh;
  opacity: 1;
  padding: 1.5rem 0 0 0; //Top padding for round title
  position: relative;
  transition: all ease $transition-speed;
  justify-content: space-between;

  //   &:not(.nopad):before,
  //   &:not(.nopad):after {
  //     content: " ";
  //     display: inline-block;
  //     flex-grow: 0.5;
  //   }

  @include print {
    min-width: 0;
    width: $bracket-print-round-width;
    margin: 0 $bracket-print-round-padding;
  }
}

.round-leaf {
  min-height: calc(11rem + 6px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 4;
  padding: 0.25rem 0;
  transition: all ease $transition-speed;

  &.round-ancestors-3 {
    flex-grow: 3;
  }

  &.round-ancestors-2 {
    flex-grow: 2;
  }

  .round-1 &,
  .round-first-active &,
  &.round-leaf-start,
  &.bottom,
  &.top,
  &.half {
    min-height: calc(5rem + 3px);
    flex-grow: 1;
  }

  .round-1 &.bottom {
    justify-content: flex-start;
  }

  .round-1 &.top {
    justify-content: flex-end;
  }

  .round-last & {
    min-height: unset;
  }
}

.round-leaf-leads-up,
.round-leaf-leads-down {
  // borders
  position: relative;
  &:after,
  &:before {
    content: " ";
    border-color: $gray-300;
    border-left-width: 1px;
    border-left-style: solid;
    position: absolute;
    right: -17px;

    @include print {
      right: -4px;
    }
  }
}

.round-leaf-leads-up {
  &:before {
    top: 0;
    bottom: 50%;
  }
}
.round-leaf-leads-down {
  &:after {
    top: 50%;
    bottom: 0;
  }
}

// When a round is not in view to the left
.round-hidden {
  opacity: 0;
  max-height: 0vh;

  &:before,
  &:after,
  & .fill {
    min-height: 0rem;
  }

  .round-leaf {
    flex-grow: 0;
    min-height: 0;
  }

  .bracket-match {
    height: 0;
    min-height: 0;
  }
}

// Match styles
.bracket-match {
  border: 1px solid $gray-300;
  display: flex;
  position: relative;

  //Height is set to animate when changing rounds
  height: calc(
    #{$bracket-match-cell-height * 2} + 3px
  ); //height of each match cell * 2 for total bracket match + 3px for borders
  transition: height ease $transition-speed;

  @include print {
    border: 1px solid $gray-600;
    height: calc(
      #{$bracket-print-match-cell-height * 2} + 3px
    ); //height of each match cell * 2 for total bracket match + 3px for borders
  }

  .round-last & {
    height: calc(
      #{$bracket-match-cell-height} + 2px
    ); //Last round or winner has one match and one less border

    @include print {
      height: calc(
        #{$bracket-print-match-cell-height} + 2px
      ); //Last round or winner has one match and one less border
    }
  }

  // Round connecting borders
  &:after,
  &:before {
    border-top: 1px solid $gray-300;
    content: "";
    display: block;
    margin-top: -0.5px;
    position: absolute;
    top: 50%;

    @include print {
      border: 1px solid $gray-600;
    }
  }

  // Turn off round connector borders depending on column
  .round-last &:after,
  .round-1 &:before {
    border-top-width: 0;
    border-width: 0;
  }

  // Left round connecting border
  &:before {
    left: calc(#{-$bracket-round-padding} - 1px);
    right: 100%;

    //hide left round connecting border on loser rounds
    .loser-round.even & {
      display: none;
    }

    @include print {
      left: calc(#{-$bracket-print-round-padding} - 1px);
    }
  }

  // Right round connecting border
  &:after {
    left: 100%;
    right: calc(#{-$bracket-round-padding} - 1px);

    @include print {
      right: calc(#{-$bracket-print-round-padding} - 1px);
    }
  }

  .bracket-match-mid-border {
    border-top: 1px solid $gray-300;

    @include print {
      border-top: 1px solid $gray-600;
    }
  }

  .bracket-match-round {
    background: $brand;
    color: $white;
    min-width: 2rem;
    align-items: center;
    border-right: 1px solid $gray-300;
    display: flex;
    font-weight: bold;
    justify-content: center;
    padding: 0 0.4rem;

    @include print {
      border-right: 1px solid $gray-600;
      color: $gray-600;
      background: $gray-200;
      //font-weight: 100;
      font-size: 0.6rem;
      padding: 0;
      min-width: 1.4rem;
    }
  }

  .bracket-match-registrants {
    flex-grow: 1;
    overflow: hidden;
  }
}

.bracket-match.start-bracket-match:before {
  display: none;
}

.empty-match {
  display: flex;
  position: relative;
  height: calc(5rem + 3px);
  transition: height ease $transition-speed;
}

.empty-match2 {
  display: flex;
  position: relative;
  height: calc(3.5rem + 3px);
  transition: height ease $transition-speed;
}

.bracket-match-holder {
  // Round connecting borders
  &:after,
  &:before {
    border-top: none;
  }
}

.match-cell {
  background: $gray-100;
  display: flex;
  height: $bracket-match-cell-height;
  overflow: hidden;

  @include print {
    height: $bracket-print-match-cell-height;
    background: none;
  }

  &:hover {
    background: lighten($brand, 50%);
  }

  .match-cell-info {
    background: $gray-600;
    align-items: center;
    color: $white;
    display: flex;
    font-size: 0.6rem;
    justify-content: center;
    min-width: 1.7rem;
    padding: 0 0.2rem;
    vertical-align: middle;

    @include print {
      //font-size: 5px;
      min-width: 10px;
      background: none;
      color: $gray-600;
    }
  }

  .match-cell-registrant {
    padding: 0.2rem 0.5rem;
    align-items: baseline;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    max-width: 100%;
    overflow: hidden;

    @include print {
      padding: 0.2rem;
    }
  }

  .match-cell-registrant-name {
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.3;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @include print {
      font-size: 0.7rem;
    }
  }

  .match-cell-team {
    font-size: 0.6rem;

    @include print {
      font-size: 6px;
    }
  }
}

.round-title {
  color: $gray-600;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  font-weight: 800;
}

// Fills empty space in bracket to keep rounds aligned
.fill {
  display: none;
}
// .fill {
//   flex-grow: 1;
//   position: relative;

//   // Fontsize and min height allow animation to shrink hidden rounds while keeping min-height in between round 1 matches
//   font-size: 0;
//   min-height: 1rem;

//   @include print {
//     min-height: 0.85rem;
//   }

//   // Match connector line between rounds. Vertical line connecting the two previous matches to the next round.
//   .EVENT_DOUBLE &.odd:after,
//   .EVENT_SINGLE &.odd:after {
//     content: "";
//     border-right: 1px solid #ddd;
//     bottom: calc(#{-$bracket-match-cell-height} - 2px); // 2px to handle borders
//     display: block;
//     position: absolute;
//     right: -$bracket-round-padding;
//     top: calc(#{-$bracket-match-cell-height} - 2px); // 2px to handle borders

//     @include print {
//       border-right: 1px solid $gray-600;
//       bottom: calc(#{-$bracket-print-match-cell-height} - 2px); // 2px to handle borders
//       right: -$bracket-print-round-padding;
//       top: calc(#{-$bracket-print-match-cell-height} - 2px); // 2px to handle borders
//     }
//   }
// }

.fill-ROUND_ROBIN {
  flex-grow: 1.4;
}

.build-bracket-criteria-title {
  background-color: $gray-100;
  border-top: 1px solid $gray-300;
  border-bottom: 1px solid $gray-300;
  color: $gray-700;
  margin-bottom: 0;
  padding: 1rem;

  @include print {
    padding: 0 1rem;
    line-height: 37px;
  }
}

.build-bracket-registrant-table {
  font-size: 0.9rem;

  .original-applicant-row {
    td,
    th {
      padding-bottom: 0.4rem;
      padding-top: 0.4rem;
    }
  }
}
