.loader{
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: $loader-background-color;
	z-index: 10000;
	
	.loader-anim{
		position: absolute;
		top: 50%;
		left: 50%;
		width: $loader-width;
		margin-left: calc($loader-width / 2 ) * -1 ;
		margin-top: calc($loader-width / 2 ) * -1 ;
	}

	.cls-1,
	.cls-2 {
		fill: $loader-icon-color;
	}
}