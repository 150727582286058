.moment-picker {
  th,
  td {
    border-radius: 2px;

    &:hover {
      background-color: $gray-200;
      background-image: none;
    }

    &.selected {
      border-color: transparent;
      background-color: $brand;
      background-image: none;
    }
  }

  .day-view {
    th,
    td {
      min-width: 5em;
    }

    td {
      font-size: 0.9em;
    }
  }
}
