.card.section{
    border-left: 10px solid $brand;
    margin-bottom: $spacer * 2;
    
    &.no-border{
        border-left-width: 0;	
    }
    
    &.thin{
        border-left-width: 2px;
    }

    .card-header{
        border-bottom: 1px solid $gray-300;
        padding-left: 0;
        padding-right: 0;
        margin-left: $card-spacer-x;
        margin-right: $card-spacer-x;
        
        &.no-border{
        		border-bottom: 0;
        }
    }
    
    ::-webkit-scrollbar {
		    -webkit-appearance: scrollbarthumb-vertical;
		} 
}
