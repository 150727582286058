.readmore-visible {
    .readmore-ellipsis,
    .readmore-trigger-show {
        display: none;
    }
}

.readmore-hidden {
    .readmore-moretext,
    .readmore-trigger-hide {
        display: none;
    }
}
