.treecontrol {
  /* prevent user selection */
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  /* default */
  color: #555;
  font-size: 13px;
  font-family: Verdana,Helvetica,Arial,sans-serif;
}

.treecontrol ul {
  margin: 0;
  padding: 0;
  list-style: none;
  border: none;
  overflow: hidden;
}

.treecontrol li {
  position: relative;
  padding: 0 0 0 20px;
  line-height: 20px;
}

.treecontrol li i.tree-branch-head {
  cursor: pointer;
}

.treecontrol.bracket-tree {
  color: $gray-600;

  li {
    padding-left: 0.6rem;
  }

  .tree-label {
    padding: 3px 0;
    display: block;

    &:hover {
      background: #eee;
    }
  }

  li.tree-leaf {
    color: $brand;
  }

  .tree-leaf.tree-selected .tree-label,
  .tree-leaf.tree-selected .tree-label:hover {
    color: $white;
    background-color: $brand;
  }

  li.tree-leaf i.tree-leaf-head,
  li.tree-expanded i.tree-branch-head,
  li.tree-collapsed i.tree-branch-head {
    padding: 7px;
    display: inline-block;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 7px;
  }

  li.tree-expanded i.tree-branch-head {
    background-image: url("../images/node-opened.png");
  }

  li.tree-collapsed i.tree-branch-head {
    background-image: url("../images/node-closed.png");
  }

  li.tree-leaf i.tree-leaf-head {
    background-image: none;
  }
}
