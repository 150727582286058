fieldset {
  margin-bottom: $spacer * 2;

  legend {
    color: $gray-600;
    padding-top: calc($spacer/2);
    font-weight: 300;
    font-size: 1.3rem;
  }

  &:not(:first-child).section {
    legend {
      border-top: 1px solid $gray-300;
    }
  }
}

label.required:after {
  content: " *";
}

.no-focus:focus {
  box-shadow: none !important;
}

.form-control-focus {
  box-shadow: $input-btn-focus-box-shadow;
  border-color: $input-focus-border-color;
  position: relative; // Added to get box-shadow over other inputs
}

.invalid-feedback {
  display: block;
  // @extend .invalid-feedback;
}

.input-read-only {
  position: relative;
  &:after {
    content: " ";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.input-hidden {
  height: 0 !important;
  width: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  overflow: hidden !important;
  opacity: 0 !important;
  border-width: 0 !important;
  position: absolute;
}

.select-place-holder {
	width: 54px;
}
