
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    word-wrap: break-word;

    span{
      display: block;
      font-size: 43%;
    }

    &.thin{
    	font-weight: 400;
    }
}

.hband{
    border-left: 10px solid $brand;
    padding-left: 10px;
    transform: translateX(-23px);
}


.list-inline {
	margin-bottom: 0;
}
