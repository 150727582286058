.myevent-title {
	color: $myevents-menu-item-color;
}

.myevents-menu .menu-item {
	font-size: medium;
	color: $myevents-menu-item-color;
}

.myevents .event-name {
	color: $brand;
	font-size: 24px;
	font-weight: 400;
}