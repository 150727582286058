// Force print preview when html tag has print class
@mixin print {
    @media print {
        @content;
    }

    // Force print preview when under a print class
    .print & {
        @content;
    }
}

// look like a printed page in browser
@mixin printPage {
    // Some print styles from paper-css https://github.com/cognitom/paper-css
    @media screen {
        background: white;
        box-shadow: 0 0.5mm 2mm rgba(0, 0, 0, 0.3);
        min-height: 296mm;
        margin: 5mm auto;
        width: 210mm;
    }
}

@page {
    margin: 0;
    padding: 0;
}

html.print {
    &,
    body {
        background-color: $gray-300;
    }

    @media print {
        &,
        body {
            background-color: $white;
        }
    }
}
