html,
body {
    min-height: 100vh;
    background-color: $body-bg;
}

.app-body {
    background-color: $body-bg;
    position: relative;
    z-index: 1;
}
