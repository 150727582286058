$brand: #6cc217;
$red: #dc3545 !default;
$green: #28a745 !default;

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

//colors
$build-bracket-criteria-title-bg: #f2f2f2;
$build-bracket-criteria-title-color: #005580;
$build-bracket-applicants-border: #eceeef;
$build-bracket-multi-menu-bg: #f2f2f2;
$build-bracket-multi-menu-border: #cdcdcd;

$scheduled-event-category-values-color: $gray-800;
$scheduled-event-category-values-bg: $gray-100;

$event-status-open-color: #00ab44;
$manage-event-grey-color: #404040;
$manage-event-table-row-border: #dddddd;
$myevents-menu-item-color: #666a73;

// Brackets
$bracket-round-padding: 1rem;
$bracket-print-round-padding: 0.25rem;
$bracket-round-width: 200px;
$bracket-print-round-width: 15%;
$bracket-match-cell-height: 2.5rem;
$bracket-print-match-cell-height: 1.75rem;

// deleting
$deleting-blocker-index: 10;
$deleting-opacity: 0.4;
$deleting-text-decoration: line-through;

// Footer
$footer-desktop-height: 6rem;

// Loader
$loader-width: 150px;
$loader-background-color: rgba(255, 255, 255, 0.5);
$loader-icon-color: $gray-400;
