header {
  background: $black;

  .navbar-brand {
    text-transform: uppercase;
    font-weight: bold;
    padding: 0;

    span {
      display: none;
      @media (min-width: 380px) {
        display: inline;
      }
    }
  }

  .navbar-nav {
    text-align: center;
    font-size: 1rem;
    font-weight: 400;
  }

  .nav-alerts {
    color: $navbar-dark-color;
    padding-left: 1.5rem;
    position: relative;
    margin: -0.3rem 0;

    &:hover {
      .fa {
        animation-name: "tada";
      }
    }

    .badge {
      position: absolute;
      top: 0;
      right: 0.8rem;
      border-radius: 100%;
    }
  }

  .alertsDropdown {
    @include media-breakpoint-up(lg) {
      width: 25rem;
    }

    .dropdown-item {
      white-space: normal;

      &:hover {
        color: $white;
        background-color: $gray-900;
      }
    }
  }

  .alert-action {
    font-weight: bold;
  }

  .dropdown-menu {
    background: $black;
  }

  // Keep main nav and dropdown the same on mobile
  .navbar-nav,
  .accountDropdown .dropdown-item {
    @include media-breakpoint-down(md) {
      color: rgba(255, 255, 255, 0.5);
      text-align: center;
      font-size: 2rem;
      font-weight: 300;
      padding: 0.5rem 1rem;

      &:hover {
        color: rgba(255, 255, 255, 0.75);
        background: transparent;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .navbar-lg-fixed & {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: 10;
      background: rgba(0, 0, 0, 0.4);
    }

    .nav-item-display > a {
      &:hover,
      &.active {
        position: relative;

        &:after {
          content: " ";
          height: 0.5rem;
          width: 100%;
          position: absolute;
          bottom: 100%;
          left: 0;
          right: 0;
          background-color: $brand;
        }
      }
    }
    // Hide active state when mousing over other nav items
    ul:hover li a.active:not(:hover):after {
      background-color: transparent;
    }

    .navbar-expand-lg .navbar-nav .nav-link {
      padding-right: 1.5rem;
      padding-left: 1.5rem;
    }
  }

  // Show dropdown on mobile in toggle nav
  .dropdown-toggle {
    @include media-breakpoint-down(md) {
      display: none;
    }
  }
  .accountDropdown {
    @include media-breakpoint-down(md) {
      display: block;
      background: transparent;
      padding: 0;
      margin: 0;
    }
  }
}
