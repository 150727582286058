.quick-steps {
    background-color: $white;
    overflow: hidden;
    padding-top: ($spacer * 3);
    padding-bottom: ($spacer * 3);
}

.quick-steps-list {
    padding: ($spacer * 3) $spacer $spacer;

    @include media-breakpoint-up(md) {
        padding: ($spacer * 3) 0 0 0;
    }
}

.quick-steps-step {
    padding-bottom: ($spacer * 1);
    position: relative;

    h5{
        font-size: 1.1rem;
        margin-bottom: 1rem;
    }
}

.quick-steps-index {
    font-size: 6rem;
    font-weight: bold;
    line-height: 1;
    opacity: .07;
    position: absolute;
    right: 0;
    top: -1rem;
    z-index: 0;

    @include media-breakpoint-up(md) {
        font-size: 18rem;
        right: -1rem;
        top: -4rem;
    }

}
