html .input-daterange input:first-child,
html .input-daterange input:last-child {
  border-radius: 0;
}

.datepicker {
  border: 0;
  padding: 0;
  height: unset;
  display: inline;
  font-weight: 200;
  color: $gray-700;
}

.datepicker:disabled,
.datepicker[readonly] {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding-left: 36px; // space for calendar icon
  background: transparent;
}

.datepicker-input {
  white-space: nowrap;
  width: 10rem;
  position: relative;
}

.date {
  border: 1px solid $gray-400;
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
