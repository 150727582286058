.view-applicants-table {
	font-size: small;
}

.view-applicants-bracket {
	color: $gray-700;
}

.view-applicants-table {
 	.nx-dropdown-item {
		color: $brand;
	}
	
	.nx-dropdown-item:active, .nx-dropdown-item.active {
		color: $white;
	}
}