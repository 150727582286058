.deleting {
    opacity: $deleting-opacity;
    position: relative;
    text-decoration: $deleting-text-decoration;
}

.deleting-blocker {
    display: none;
}

.deleting .deleting-blocker {
    bottom: 0;
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: $deleting-blocker-index;
}
