.heading {
  background: linear-gradient(
    168deg,
    #6cc217,
    #008a00 70%,
    #006f00
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

  .heading-ing {
    background: url("/assets/images/heading/bg.png");
    color: $white;
    padding-bottom: $spacer;
    padding-top: $spacer;

    @include media-breakpoint-up(md) {
      padding-top: $spacer * 4;
      padding-bottom: $spacer * 1;
    }
  }

  .heading-secondary {
    color: $gray-300;
    font-weight: 200;
  }

  &.pageheader {
    .heading-ing {
      color: $black;
      padding-bottom: 0;
    }

    .heading-secondary {
      color: $gray-500;
    }

    .heading-page {
      background: $white;
    }
  }
}
