.section-nav{
	background-color: $gray-100;
	border-top: 1px solid $gray-400;
	border-bottom: 1px solid $gray-400;
	
	@include media-breakpoint-up(md) {
		padding: 0 1rem;
	}

	.container{
		padding-right: $navbar-padding-x;
		padding-left: $navbar-padding-x;
	}
	
	.nav-link{
		color: $gray-700;
		text-align: center;
		@include media-breakpoint-up(md) {
			border-bottom: 2px solid transparent;
			text-align: left;
		}
		
		&:hover{
			border-bottom-color: $gray-300;
		}
		
		&.active{
			border-bottom-color: $brand;
		}
	}

	
	.btn{
		@include media-breakpoint-down(md) {
			display: none;
		}
		@include media-breakpoint-up(md) {
			height: 100%;
		}
	}

	.navbar-toggler{
		width: 100%;
		
		i{
			transform: rotate(-180deg);
			transition: transform ease .2s;
		}
		
		&.collapsed{
			i{
				transform: rotate(0deg);
			}
		}
		&:focus{
			outline: none;
		}
	}
}