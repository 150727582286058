.date-picker-input {
  width: 130px;
}

.date-picker-addon {
  padding: 0.2em 0.5em 0.2em 0.5em;
}

.scheduled-event-ticket-table {
  .quantity {
    width: 3.5rem;
  }

  input.price {
    width: 70px;
  }

  .date {
    width: 100px;
  }

  td {
    vertical-align: top;
  }

  .price .invalid-feedback {
    max-width: 100px;
  }
}

.scheduled-event-last-updated {
  color: $manage-event-grey-color;
  font-size: small;
}

.scheduled-event-team-list {
  border: $input-btn-border-width solid$input-border-color;
  padding: 0;

  li {
    padding: 0.5rem;

    &:not(:first-child) {
      border-top: 1px solid $gray-300;
    }
  }
}

.scheduled-event-team-list {
  .team-entry-remove {
    cursor: pointer;
  }
}

body.dragging,
body.dragging * {
  cursor: move !important;
}

.dragged {
  position: absolute;
  opacity: 0.5;
  z-index: 2000;
}

.scheduled-event-criteria-template-overflow {
  overflow: auto;
}

.scheduled-event-criteria-template-display {
  display: flex;
  min-width: 800px;
}

.scheduled-event-criteria-template-display .criteria-item {
  padding: 0.2rem;
  width: 20%;
}

.scheduled-event-criteria-template-display button {
  font-weight: normal;
}

.scheduled-event-criteria-edit-text {
  position: relative;
}

.scheduled-event-criteria-edit-ui {
  position: absolute;
  right: 0;
  top: -0.5rem;
  bottom: 0;
}

.scheduled-event-category-values.saving {
  opacity: 0.5;
}

.scheduled-event-category-values li {
  background-color: $scheduled-event-category-values-bg;
  display: flex;
  margin: 0.4rem 0;
  padding: 0.4rem;
}

.scheduled-event-category-values .placeholder {
  opacity: 0.8;
}

.scheduled-event-category-values .category-value {
  flex-grow: 1;
  //https://css-tricks.com/flexbox-truncated-text/
  min-width: 0;
}

.scheduled-event-category-values .category-value a {
  color: $scheduled-event-category-values-color;
  padding: 0;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.category-value-menu {
  white-space: nowrap;
}

.scheduled-event-category-values li.placeholder {
  position: relative;
}
.scheduled-event-category-values li.placeholder:before {
  position: absolute;
}

.cropArea {
  width: 100%;
  background: $gray-100;
  overflow: hidden;
  position: relative;
  padding: 1rem 0.5rem 0;
}
ui-cropper {
  width: 100%;
  display: block;
  height: 100%;
  position: relative;
  overflow: hidden;

  canvas {
    display: block;
    margin: 0 auto !important; //Override inline styles, why?
  }
}

.team-entry:hover {
  cursor: pointer;
}

a.help {
  color: inherit;
}

.custom-select {
  color: $gray-900;
  border: 1px solid $gray-400;
  border-radius: 0;
}
