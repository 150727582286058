.btn {
  font-weight: 500;
  //border-radius: $btn-border-radius;
}
.btn + .btn {
  //Not sure why this is needed, as docs show a space between button
  margin-left: calc($spacer/2);
}

.btn-primary,
// All the other bootstrap classes to prevent the fight
.btn-check:checked + .btn-primary,
.btn-check:active + .btn-primary,
.btn-primary:active,
.btn-primary.active,
.show > .btn-primary.dropdown-toggle {
  color: $white;

  &:hover {
    color: $white;
  }
}

.btn-primary:disabled {
	background-color: $gray-400;
} 