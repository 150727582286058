//
// Color system
//

$blue: #007bff !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #dc3545 !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$green: $brand !default;
$teal: #20c997 !default;
$cyan: #17a2b8 !default;

// scss-docs-start theme-color-variables
$primary: $brand;
$success: $brand;

$min-contrast-ratio: 2.5;

// Links
//
$link-decoration: none;
$link-hover-decoration: underline;
$link-color: $brand;

// Fonts
//
// Font, line-height, and color for body text, headings, and more.
$font-family-sans-serif: "Helvetica Neue", -apple-system, BlinkMacSystemFont, Roboto, Arial,
  sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$font-weight-light: 200 !default;
$font-weight-normal: 200 !default;
$headings-font-weight: bold;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.
//$enable-rounded: false !default;
//$border-radius: 0;

// Card
$card-bg: $white;
$card-border-width: 0;
$card-cap-bg: transparent;
$card-border-color: rgba($black, 0.125) !default;

// Body
$body-bg: $gray-200;

// Buttons
//$btn-border-radius: 0;

$input-bg: #fff;

// Breadcrumbs
$breadcrumb-padding-y: 0.25rem !default;
$breadcrumb-padding-x: 0 !default;
$breadcrumb-item-padding: 0.5rem !default;
$breadcrumb-margin-bottom: 1rem !default;
$breadcrumb-bg: $gray-300 !default;
$breadcrumb-divider-color: $gray-600 !default;
$breadcrumb-active-color: $black !default;
$breadcrumb-divider: "/" !default;

// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.
$table-bg: transparent !default;
$table-accent-bg: transparent !default;

// Navs
$navbar-padding-x: 1rem;
$nav-tabs-link-active-bg: $gray-100 !default;

// Grid container acts like container fluid
$container-max-widths: (
  sm: 1191px,
  md: 1192px,
  lg: 1194px,
  xl: 1195px,
) !default;

// Dropdowns
//
// Dropdown menu container and contents.

$dropdown-bg: $white !default;
$dropdown-border-color: rgba($black, 0.15) !default;
$dropdown-link-color: $gray-400 !default;
$dropdown-link-hover-color: darken($gray-900, 5%) !default;
$dropdown-link-hover-bg: $gray-100 !default;

$modal-backdrop-bg: rgba($black, 0.7);

//list group
$list-group-bg: $white;

//disable dark mode
$enable-dark-mode: false;
