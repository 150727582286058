@use "sass:math";

.megahero {
  position: relative;

  /* Mobile hero image height/width */
  padding-bottom: percentage(math.div(400, 700));
  @media (min-width: 800px) {
    /* desktop hero image height/width */
    padding-bottom: percentage(math.div(744,1920));
  }
}

.megahero-foreground {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  margin: 0;
  z-index: 5;
}

.megahero-caption {
  color: $white;
  text-shadow: 0.2rem 0.2rem 0.6rem rgba(0, 0, 0, 0.8);
  padding-bottom: 0.2rem;

  h1 {
    font-size: 2rem;
    @include media-breakpoint-up(md) {
      font-size: 3rem;
    }
  }
}

.megahero-carousel {
  position: absolute;
  top: 0;
  left: 0;
}

.megahero-carousel picture,
.megahero-carousel img {
  object-fit: cover;
}
