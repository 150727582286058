.ng-textMaxLength {
    position: relative;
}

.ng-textMaxLength > textarea {
    padding-bottom: 2rem;
}

.ng-textMaxLength-limit {
    background: $white;
    padding: 0.25rem;
    text-align: right;
}

.is-invalid .ng-textMaxLength-limit {
    color: $form-feedback-invalid-color;
    font-weight: bold;
}
