.nxInlineEdit {
    position: relative;
}

.nxInlineEdit input {
    margin-bottom: .5rem;
}

.nxInlineEdit .btn + .btn {
    margin-left: .25rem;
}

.nxInlineEdit-saving {
    background: rgba(250,250,250, .9);
    bottom: 0;
    left: 0;
    padding: .2rem;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 5;
}

.nxInlineEdit-text-display {
    cursor: pointer;
    display: block;
    font-size: $font-size-base;
    padding: 0 $input-btn-padding-x;
}

.nxInlineEdit-link .nxInlineEdit-text-display {
    color: $brand;
    font-weight: normal;
}

.nxInlineEdit-text-display:hover {
    text-decoration: underline;
}
