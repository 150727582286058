.text-black {
	color: #000;
}

.hide {
	display: none;
}

.page-break {
	page-break-before: always;
	border-top: 1px solid;
	padding-bottom: 10px;
}

label {
	margin-bottom: .5rem;
}

//honeypot selector
.hopov {
	position: absolute;
	left: -10000px;
	top: auto;
	width: 1px;
	height: 1px;
	overflow: hidden;
}