.modal.ng {
    display: block;
    background: $modal-backdrop-bg;
}

.modal .modal-body.scroll {
    max-height: 80vh;
    overflow: auto;
}

.modal-dialog {
    max-height: 96vh;
}

.modal-body {
    max-height: 100%;
}

.modal-footer {
    padding: $modal-header-padding;
}