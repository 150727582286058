.scanbox{
    background: $gray-900;
    min-height: 300px;
    position: relative;
    display: flex;
    justify-content: center;
    align-content: center;
}

.scanbox-message {
    align-self: center;
    text-align: center;
}

.scanbox canvas {
    display: block;
}