.nav.nav-quick{

	@include media-breakpoint-up(md) {
		border-left: 1px solid $gray-300;
		height: 100%;
	}

	.nav-link{
		color: $gray-800;

		&:hover,
		&.active{
			background: $gray-100;
		}
	}

	.nav-icon{
		min-width: 1.1rem;
		margin-right: .5rem;
	}


}
