// Current version of bootstrap only hides inputs in btn-group-toggle when using javascript plugin.
// Plugin breaks angular work.
// https://getbootstrap.com/docs/4.0/components/buttons/#checkbox-and-radio-buttons

.btn-group-toggle>.btn {
    cursor: pointer;
}

.btn-group-toggle>.btn input[type=checkbox],
.btn-group-toggle>.btn input[type=radio] {
    position: absolute;
    clip: rect(0,0,0,0);
    pointer-events: none;
}
