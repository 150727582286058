.event-status {
	color: $gray-900;
	@extend .badge;
}
.event-status-CLOSED{
	@extend .bg-secondary;
}
.event-status-OPEN {
	@extend .bg-light;
}
.event-status-UNPUBLISHED{
	@extend .bg-warning;
}