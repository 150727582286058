.band {
  background: $gray-100 center center no-repeat;
  background-size: cover;
  padding: 6vh 10vw;

  @include media-breakpoint-up(md) {
    padding: 13vh 10vw;
  }
  overflow: hidden;

  h3 {
    font-size: $font-size-base * 3;
  }

  &.dark {
    background-color: $gray-900;

    h3,
    p {
      color: $white;
    }
  }
}

.band {
  &.realtime-results {
    .backet-animation {
      width: 100%;
      position: relative;

      .cover {
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1;
      }

      .round {
        min-width: 150px;
        width: 50%;
      }

      .bracket,
      .round {
        padding: 0;
      }

      .match-cell,
      .match-cell:hover {
        background: $white;
      }
    }
  }

  &.custom-brackets {
    background-image: url("/assets/images/home/customBrackets/bg.jpg");
  }

  &.sell-tickets {
    background-image: url("/assets/images/home/sellTickets/bg.jpg");
    background-repeat: repeat;
    background-size: auto;

    .tickets {
      height: 85px;
      position: relative;
      @include media-breakpoint-up(md) {
        height: 100%;
      }

      .ticket {
        background: url("/assets/images/home/sellTickets/tickets.png");
        width: 209px;
        height: 510px;
        display: block;
        position: absolute;
        top: -100px;
        left: 50%;

        transform: translateX(-78%) rotate(70deg);
        &:nth-child(2) {
          transform: translateX(-68%) rotate(78deg);
        }
        &:nth-child(3) {
          transform: translateX(-50%) rotate(87deg);
        }

        @include media-breakpoint-up(md) {
          top: -5vh;
          transform: translateX(-78%) rotate(-20deg);
          &:nth-child(2) {
            transform: translateX(-67%) rotate(-3deg);
          }
          &:nth-child(3) {
            transform: translateX(-50%) rotate(12deg);
          }
        }
      }
    }
  }
  &.get-started {
    background-image: url("/assets/images/home/getStarted/bg.jpg");
  }

  .sampleImg {
    @include media-breakpoint-down(md) {
      margin-top: 2rem;
    }
  }
}
